/**
 * Configuration file for TOPOS. Configuration that is common for all
 * environments (dev, int, prod) can be added here.
 * Values in this file can be overwritten in environment.js, environment-dev.js ect.
 */
export const configuraton = {
  /**
   * If true: Disable Angular's development mode, which turns off assertions and other checks within the framework.
   */
  production: false,

  /**
   * Url to KundenLoginPost.
   */
  klpUrl: 'https://service.post.ch/kvm/app/ui/settings/pkp',

  /**
   * Url to browser location activation manual.
   */
  locationPermissionManualUrl: 'https://support.google.com/maps/answer/2839911',

  serviceTypeHintsGroupName: ['Hinweise', 'Informations', 'Informazioni', 'Informations'],

  pickMupOpeningTimesLinks: {
    de: 'https://filialen.migros.ch/de?pickupId=',
    fr: 'https://filialen.migros.ch/fr?pickupId=',
    it: 'https://filialen.migros.ch/it?pickupId=',
    en: 'https://filialen.migros.ch/en?pickupId=',
  },

  /**
   * A link to 'Terminkoordinator' will be shown in detail view for some poi-types.
   */
  postAppointmentServiceTypeIds: ['001140000' /*Service "Termin vereinbaren"*/],
  postAppointmentLinks: {
    de: 'https://post-test.qsome.ch/main/#/integration/appointment-request/de',
    fr: 'https://post-test.qsome.ch/main/#/integration/appointment-request/fr',
    it: 'https://post-test.qsome.ch/main/#/integration/appointment-request/it',
    en: 'https://post-test.qsome.ch/main/#/integration/appointment-request/en',
  },

  /**
   * A link to 'Terminkoordinator' will be shown in detail view for some poi-types.
   */
  postFinanceAppointmentPoiTypeIds: ['001PFFIL' /*PostFinance-Standorte*/, '004PSTMAT' /*Postomat*/],
  postFinanceAppointmentLinks: {
    de: 'https://www.postfinance.ch/de/privat/support/persoenliche-beratung.html',
    fr: 'https://www.postfinance.ch/fr/particuliers/assistance/conseil-personnalise.html',
    it: 'https://www.postfinance.ch/it/privati/assistenza/consulenza-personalizzata.html',
    en: 'https://www.postfinance.ch/en/private/support/personal-advice.html',
  },

  /**
   * A link to the self-service point business client offers will be shown in detail view for some poi-types.
   */
  servicePointBusinessOfferPoiTypeIds: ['001GKSS'],
  servicePointBusinessOfferLinks: {
    de: 'https://www.post.ch/de/geschaeftsloesungen/kmu/geschaeftskundenstelle-self-service',
    fr: 'https://www.post.ch/fr/solutions-commerciales/pme/geschaeftskundenstelle-self-service',
    it: 'https://www.post.ch/it/soluzioni-commerciali/pmi/geschaeftskundenstelle-self-service',
    en: 'https://www.post.ch/en/business-solutions/sme/geschaeftskundenstelle-self-service',
  },

  /**
   * A link to the mp24 without display manual will be shown in detail view of the concerning mp24 pois.
   */
  mp24WithoutDisplayServiceIds: ['001490011'],
  mp24WithoutDisplayLinks: {
    de: 'https://www.post.ch/de/empfangen/empfangsorte/pickpost-my-post-24/my-post-24#mobile',
    fr: 'https://www.post.ch/fr/reception/lieux-de-reception/pickpost-my-post-24/my-post-24#mobile',
    it: 'https://www.post.ch/it/ricezione/luoghi-di-ricezione/pickpost-my-post-24/my-post-24#mobile',
    en: 'https://www.post.ch/en/receiving-mail/receipt-locations/pickpost-my-post-24/my-post-24#mobile',
  },

  /**
   * A link to the postfachanlage offer will be shown in detail view of the concerning mp24 pois.
   */
  postfachanlageOfferPoiTypeIds: ['001PFST'],
  postfachanlageOfferLinks: {
    de: 'https://www.post.ch/de/empfangen/empfangsorte/postfach',
    fr: 'https://www.post.ch/fr/reception/lieux-de-reception/case-postale',
    it: 'https://www.post.ch/it/ricezione/luoghi-di-ricezione/casella-postale',
    en: 'https://www.post.ch/en/receiving-mail/receipt-locations/po-box',
  },

  /**
   * Partner service group names.
   */
  partnerServiceGroups: [
    'Swisscaution',
    'Assura',
    'Sympany',
    'Migros Bank',
    'Cornèr/Cornèrcard/Cornèrtrader',
    'AXA',
    'Groupe Mutuel',
    'philoro SCHWEIZ AG',
    'Western Union',
    'Banque du Léman',
    'Bernerland Bank',
  ],

  /**
   * Partner service group logos.
   */
  partnerLogos: [
    {
      name: 'Swisscaution',
      imgUrl: '/assets/logos/swisscaution.png',
      imgHeight: '40',
    },
    {
      name: 'Assura',
      imgUrl: '/assets/logos/assura.png',
      imgHeight: '20',
    },
    {
      name: 'Sympany',
      imgUrl: '/assets/logos/sympany.png',
      imgHeight: '40',
    },
    {
      name: 'Migros Bank',
      imgUrl: '/assets/logos/migrosbank.png',
      imgHeight: '25',
    },
    {
      name: 'Cornèr/Cornèrcard/Cornèrtrader',
      imgUrl: '/assets/logos/corner.png',
      imgHeight: '25',
    },
    {
      name: 'AXA',
      imgUrl: '/assets/logos/axa.png',
      imgHeight: '45',
    },
    {
      name: 'Groupe Mutuel',
      imgUrl: '/assets/logos/groupemutuel.jpg',
      imgHeight: '30',
    },
    {
      name: 'philoro SCHWEIZ AG',
      imgUrl: '/assets/logos/philoro.jpg',
      imgHeight: '40',
    },
    {
      name: 'Western Union',
      imgUrl: '/assets/logos/westernunion.png',
      imgHeight: '40',
    },
    {
      name: 'Banque du Léman',
      imgUrl: '/assets/logos/banqueleman.png',
      imgHeight: '40',
    },
    {
      name: 'Bernerland Bank',
      imgUrl: '/assets/logos/bernerland-bank.png',
      imgHeight: '40',
    },
  ],

  /**
   * Service id's for access by wheelchair.
   */
  accessibleByWheelchairServiceIds: ['001134109', '004BF'],

  /**
   * Default center and zoom level on the map.
   */
  defaultCenterOfMap: {
    latitude: 46.8131,
    longitude: 8.224,
  },
  defaultZoomLevel: 8,

  /**
   * Due to calculatedopeninghours in XML, the datepicker is restricted to the
   * next few days. Num days/dates that are selectable in the calendar, inclusive
   * current day.
   */
  numDaysEnabledForOpenAt: 14,

  /**
   * Localized URLs for home (search)
   */
  homeRoutes: {
    de: 'standortsuche',
    fr: 'recherche-de-site',
    it: 'ricerca-ubicazioni',
    en: 'location-search',
  },
  supportedLanguages: ['de', 'fr', 'it', 'en'],
};
